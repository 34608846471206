/* eslint-disable no-console */
import { Logger } from "../service";
import { LoggerInfo, LoggerMeta } from "../types";

/**
 * A logger that outputs to the console
 */
export class ConsoleLogger implements Logger {
    /** Logs the specified info message */
    public info(message: string): void;
    public info(message: string, meta: LoggerMeta): void;
    public info(info: LoggerInfo): void;
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public info(_arg1: any, _arg2?: any): void {
        console.info.apply(console, Array.from(arguments) as any);
    }

    /** Logs the specified warning message */
    public warn(message: string): void;
    public warn(message: string, meta: LoggerMeta): void;
    public warn(info: LoggerInfo): void;
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public warn(_arg1: any, _arg2?: any): void {
        console.warn.apply(console, Array.from(arguments) as any);
    }

    /** Logs the specified error message */
    public error(message: string): void;
    public error(message: string, meta: LoggerMeta): void;
    public error(info: LoggerInfo): void;
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public error(_arg1: any, _arg2?: any): void {
        console.error.apply(console, Array.from(arguments) as any);
    }
}
