import { ReportHandler } from "web-vitals";

export function reportWebVitals(onPerfEntry?: ReportHandler) {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        import("web-vitals")
            .then(
                /* spell-checker: disable */
                function ({ getCLS, getFID, getFCP, getLCP, getTTFB }) {
                    getCLS(onPerfEntry);
                    getFID(onPerfEntry);
                    getFCP(onPerfEntry);
                    getLCP(onPerfEntry);
                    getTTFB(onPerfEntry);
                }
                /* spell-checker: enable */
            )
            .catch(function (err) {
                // eslint-disable-next-line no-console
                console.log(err);
            });
    }
}
