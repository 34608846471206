import { atom } from "recoil";
import { LinkItem } from "../models";

/**
 * The state atom keys
 *
 * See:
 * - [Atoms](https://recoiljs.org/docs/basic-tutorial/atoms}
 */
export enum StateAtomKey {
    /** The external resources */
    links = "links",
}

export const linksState = atom<readonly LinkItem[]>({
    key: StateAtomKey.links,
    default: [],
});
