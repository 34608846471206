import { blue, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const defaultTheme = createTheme({
    palette: {
        mode: "light",
        primary: blue,
        secondary: red,
    },
});
