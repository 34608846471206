import { TFunction } from "i18next";

/**
 * The translation service
 */
export interface Translator {
    /**
     * Returns the current language code.
     *
     * See:
     * - [language](https://www.i18next.com/overview/api#language)
     * - [Tags for Identifying Languages](https://www.rfc-editor.org/rfc/rfc5646.txt)
     */
    readonly lang: string;

    /**
     * Returns the available translation language codes.
     *
     * See:
     * - [languages](https://www.i18next.com/overview/api#languages)
     * - [Tags for Identifying Languages](https://www.rfc-editor.org/rfc/rfc5646.txt)
     */
    readonly languages: readonly string[];

    /**
     * Returns the translation function.
     *
     * See:
     * - [t function](https://www.i18next.com/overview/api#t)
     */
    readonly t: TFunction;

    /**
     * Changes the language.
     *
     * For testing purpose, setting lang to `cimode` will set the `t` function
     * to always return the key.
     *
     * See:
     * - [changeLanguage](https://www.i18next.com/overview/api#changelanguage)
     * - [Tags for Identifying Languages](https://www.rfc-editor.org/rfc/rfc5646.txt)
     *
     * @param lang The language code or `undefined` to detecte the language
     */
    changeLanguage(lang?: string): Promise<TFunction>;
}

/** The {@link Translator} interface type tag */
export const TranslatorTypeTag = Symbol.for("Translator");
