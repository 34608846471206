import { Container } from "inversify";
import ow from "ow";
import { isNil, isNotNil } from "./predicates";

let _container: Container | undefined;

/**
 * Gets or creates the global Dependency Injection (DI) container.
 *
 * See:
 * - [InversifyJS](https://github.com/inversify/InversifyJS)
 *
 * @returns The global DI container
 */
export function getOrCreateDIContainer(): Container {
    if (isNil(_container)) {
        _container = new Container();
    }
    return _container;
}

/**
 * Sets the global Dependency Injection (DI) container.
 *
 * See:
 * - [InversifyJS](https://github.com/inversify/InversifyJS)
 *
 * @param container The DI container
 */
export function setDIContainer(container: Container | undefined): void {
    ow(container, ow.optional.object);
    _container = container;
}

/**
 * Returns the global Dependency Injection (DI) container.
 *
 * See:
 * - [InversifyJS](https://github.com/inversify/InversifyJS)
 *
 * @returns The global DI container or `undefined`
 */
export function getDIContainer(): Container | undefined {
    return _container;
}

/**
 * Returns the global dependency injection container.
 * Throws if the global container has not been set.
 *
 * See:
 * - [InversifyJS](https://github.com/inversify/InversifyJS)
 *
 * @returns The global DI container
 */
export function getDIContainerOrFail(): Container {
    if (isNil(_container)) {
        throw new Error(
            "The Dependency Injection (DI) container has not been set."
        );
    }
    return _container;
}

/**
 * Resolves a dependency provider.
 * Throws if the dependency cannot be resolved.
 *
 * See:
 * - [InversifyJS](https://github.com/inversify/InversifyJS)
 *
 * @param tag The type tag
 * @returns The resolved type instance
 */
export function resolve<T>(tag: symbol): T {
    ow(tag, ow.symbol);

    //
    // Try to resolve dependency from the DI container
    let provider: T | undefined =
        isNotNil(_container) && _container.isBound(tag)
            ? _container.get<T>(tag)
            : undefined;

    if (isNil(provider)) {
        throw new Error(
            `The '${tag.toString()}' dependency has not been registered.`
        );
    }

    return provider;
}
