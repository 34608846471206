import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { Container } from "inversify";
import ow from "ow";
import { initReactI18next } from "react-i18next";
import en_US from "../locales/en-US/messages.json";
import es_ES from "../locales/es-ES/messages.json";
import { I18nTranslator, Translator, TranslatorTypeTag } from "../services";

type LanguageSpec = readonly [key: string, dict: Record<string, string>];

const LANGUAGES: readonly LanguageSpec[] = Object.freeze([
    ["en", en_US],
    ["en-US", en_US],
    ["es", es_ES],
    ["es-ES", es_ES],
]);

const FALLBACK_LANG = "en";

const RESOURCES = Object.freeze(
    Object.fromEntries(
        LANGUAGES.map(function ([key, dict]) {
            return [key, { messages: dict }];
        })
    )
);

const SUPPORTED_LANGS = Object.freeze(
    LANGUAGES.map(function ([key]) {
        return key;
    })
);

/**
 * Initializes the translator
 *
 * See:
 * - [Configure i18next](https://react.i18next.com/guides/quick-start#configure-i18next)
 *
 * @param container The Di container
 */
export async function initI18n(container: Container): Promise<Translator> {
    ow(container, ow.object);

    const translator = new I18nTranslator(i18n);

    container.bind<Translator>(TranslatorTypeTag).toConstantValue(translator);

    return i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            /**
             * Resources to initialize with (if not using loading or not appending using addResourceBundle)
             * @default undefined
             */
            resources: RESOURCES,
            /**
             * Language to use if translations in user language are not available.
             * @default 'dev'
             */
            fallbackLng: FALLBACK_LANG,
            /**
             * Array of allowed languages
             * @default false
             */
            supportedLngs: SUPPORTED_LANGS,
            /**
             * If true will pass eg. en-US if finding en in supportedLngs
             * @default false
             */
            nonExplicitSupportedLngs: false,
            /**
             * Language codes to lookup, given set language is
             * 'en-US': 'all' --> ['en-US', 'en', 'dev'],
             * 'currentOnly' --> 'en-US',
             * 'languageOnly' --> 'en'
             * @default 'all'
             */
            load: "all",
            /**
             * Language will be lowercased eg. en-US --> en-us
             * @default false
             */
            lowerCaseLng: false,
            /**
             * Language will be lowercased EN --> en while leaving full locales like en-US
             * @default false
             */
            cleanCode: true,
            /**
             * String or array of namespaces to load
             * @default 'translation'
             */
            ns: "messages",
            /**
             * Default namespace used if not passed to translation function
             * @default 'translation'
             */
            defaultNS: "messages",
            /**
             * String or array of namespaces to lookup key if not found in given namespace.
             * @default false
             */
            fallbackNS: false,
            /**
             * Calls save missing key function on backend if key not found.
             * @default false
             */
            saveMissing: false,
            /**
             * Calls save missing key function on backend if key not found also for plural forms.
             * @default false
             */
            saveMissingPlurals: false,
            /**
             * Allows null values as valid translation
             * @default true
             */
            returnNull: false,
            /**
             * Allows empty string as valid translation
             * @default true
             */
            returnEmptyString: true,
            /**
             * Triggers resource loading in init function inside a setTimeout (default async behavior).
             * Set it to false if your backend loads resources sync - that way calling i18next.t after
             * init is possible without relaying on the init callback.
             * @default true
             */
            initImmediate: true,
            /**
             * @see https://www.i18next.com/interpolation.html
             */
            interpolation: {
                // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
                escapeValue: false,
            },
            detection: {
                // order and from where user language should be detected
                order: [
                    // "querystring",
                    // "cookie",
                    // "localStorage",
                    // "sessionStorage",
                    "navigator",
                    // "htmlTag",
                    // "path",
                    // "subdomain",
                ],

                // keys or params to lookup language from
                lookupQuerystring: "lang",
                lookupCookie: "lang",
                lookupLocalStorage: "lang",
                lookupSessionStorage: "lang",
                lookupFromPathIndex: 0,
                lookupFromSubdomainIndex: 0,

                // cache user language on
                caches: [
                    // "localStorage",
                    // "cookie"
                ],

                // languages to not persist (cookie, localStorage)
                excludeCacheFor: ["cimode"],

                // optional expire and domain for set cookie
                // cookieMinutes: 10,
                // cookieDomain: "myDomain",

                // optional htmlTag with lang attribute, the default is:
                // htmlTag: document.documentElement,

                // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
                // cookieOptions: { path: "/", sameSite: "strict" },
            },
        })
        .then(function (_t) {
            return translator;
        });
}
