import { Logger } from "../service";
import { LoggerInfo, LoggerMeta } from "../types";

/**
 * A logger that ignores all events, for testing purpose.
 */
export class SilentLogger implements Logger {
    /** Logs the specified info message */
    public info(message: string): void;
    public info(message: string, meta: LoggerMeta): void;
    public info(info: LoggerInfo): void;
    public info(_arg1: any, _arg2?: any): void {
        /* do-nothing */
    }

    /** Logs the specified warning message */
    public warn(message: string): void;
    public warn(message: string, meta: LoggerMeta): void;
    public warn(info: LoggerInfo): void;
    public warn(_arg1: any, _arg2?: any): void {
        /* do-nothing */
    }

    /** Logs the specified error message */
    public error(message: string): void;
    public error(message: string, meta: LoggerMeta): void;
    public error(info: LoggerInfo): void;
    public error(_arg1: any, _arg2?: any): void {
        /* do-nothing */
    }
}
