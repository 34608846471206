import ow from "ow";
import { isNil, show } from "../../../common";
import { ConfigStore } from "../service";

/**
 * A configuration store that loads values from the local environment.
 */
export class LocalConfigStore implements ConfigStore {
    private readonly _store: Map<string, string>;

    /**
     * Constructs an instance of {@link LocalConfigStore}
     */
    public constructor() {
        this._store = new Map<string, string>();
    }

    /**
     * Gets the current value of the specified property.
     *
     * @param name The configuration property name
     */
    public get(name: string): string {
        ow(name, ow.string.nonEmpty);
        const value = this._store.get(name);
        if (isNil(value)) {
            throw new Error(
                `The specified configuration value is undefined: ${name}`
            );
        }
        return value;
    }

    /**
     * Tests whether the specified property is defined.
     *
     * @param name The configuration property name
     * @returns `true` if the property name is defined
     */
    public has(name: string): boolean {
        ow(name, ow.string.nonEmpty);
        return this._store.has(name);
    }

    /**
     * Sets the value of configuration property.
     *
     * @param name The configuration property name
     * @param value The configuration property value
     */
    public set(name: string, value: string): void {
        ow(name, ow.string.nonEmpty);
        ow(value, ow.string);
        this._store.set(name, value);
    }

    /**
     * Deletes a configuration property.
     *
     * @param name The configuration property name.
     */
    public delete(name: string): void {
        ow(name, ow.string.nonEmpty);
        this._store.delete(name);
    }

    /**
     * Merges the specified object properties and values into the configuration
     * store
     */
    public merge(source: Record<string, string>): void {
        ow(source, ow.object);
        const props = Object.entries(source);
        for (const [name, value] of props) {
            this._store.set(name, show(value));
        }
    }

    /**
     * Exports all the properties, the keys and their current values, as
     * a JSON compliant object.
     */
    public toJSON(): Record<string, string> {
        const props = this._store.entries();
        return Object.fromEntries(props);
    }
}
