import { i18n, TFunction } from "i18next";
import ow from "ow";
import { Translator } from "../service";

/**
 * Implements the translator service using i18n-next
 *
 * See:
 * - [Configure i18next](https://react.i18next.com/guides/quick-start#configure-i18next)
 */
export class I18nTranslator implements Translator {
    private readonly _i18n: i18n;

    /**
     * Constructs an instance of {@link I18nTranslator}
     *
     * @param i18nInstance The i18n-next instance
     *
     *
     */
    public constructor(i18nInstance: i18n) {
        ow(i18nInstance, ow.object);
        this._i18n = i18nInstance;
    }

    /**
     * Returns the current language code.
     *
     * See:
     * - [language](https://www.i18next.com/overview/api#language)
     * - [Tags for Identifying Languages](https://www.rfc-editor.org/rfc/rfc5646.txt)
     */
    public get lang(): string {
        return this._i18n.language;
    }

    /**
     * Returns the available translation language codes.
     *
     * See:
     * - [languages](https://www.i18next.com/overview/api#languages)
     * - [Tags for Identifying Languages](https://www.rfc-editor.org/rfc/rfc5646.txt)
     */
    public get languages(): readonly string[] {
        return this._i18n.languages;
    }

    /**
     * Returns the translation function.
     *
     * See:
     * - [t function](https://www.i18next.com/overview/api#t)
     */
    public get t(): TFunction {
        return this.t;
    }

    /**
     * Changes the language.
     *
     * For testing purpose, setting lang to `cimode` will set the `t` function
     * to always return the key.
     *
     * See:
     * - [changeLanguage](https://www.i18next.com/overview/api#changelanguage)
     * - [Tags for Identifying Languages](https://www.rfc-editor.org/rfc/rfc5646.txt)
     *
     * @param lang The language code or `undefined` to detecte the language
     */
    public changeLanguage(lang?: string): Promise<TFunction> {
        ow(lang, ow.optional.string);
        return this._i18n.changeLanguage(lang);
    }
}
