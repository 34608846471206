import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export default function SiteTitle() {
    const { t } = useTranslation();

    return (
        <Typography
            variant="h1"
            align="left"
            gutterBottom
            sx={{ fontSize: "2.3rem" }}
        >
            {t("site_title")}
        </Typography>
    );
}
