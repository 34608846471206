import { Container } from "inversify";
import ow from "ow";
import { MutableSnapshot } from "recoil";
import { linksState } from "./atoms";
import { ALL_LINKS } from "./data/links";

/**
 * Initializes the local state
 *
 * See:
 * - [State Initialization](https://recoiljs.org/docs/api-reference/core/Snapshot#state-initialization)
 *
 * @param container The DI container
 * @param snapshot The mutable state
 */
export function hydrateLocalState(
    container: Container,
    snapshot: MutableSnapshot
): void {
    ow(container, ow.object);
    ow(snapshot, ow.object);

    const set = snapshot.set;

    set(linksState, ALL_LINKS);
}
