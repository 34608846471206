import { useTranslation } from "react-i18next";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { HttpStatusCode } from "../../common";
import SiteTitle from "../../components/SiteTitle";
import { usePageMeta } from "../../hooks";
import HCLayout from "../../layouts/HCLayout";
import NotFoundPanel from "./parts/NotFoundPanel";
import SomeErrorPanel from "./parts/SomeErrorPanel";

function Header() {
    return <SiteTitle />;
}

function NotFoundPage() {
    const { t } = useTranslation();

    usePageMeta({ title: t("not_found_page_title") });

    return <HCLayout header={<Header />} content={<NotFoundPanel />} />;
}

function SomeErrorPage() {
    const { t } = useTranslation();

    usePageMeta({ title: t("some_error_page_title") });

    return <HCLayout header={<Header />} content={<SomeErrorPanel />} />;
}

export default function RouterErrorPage() {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        if (error.status === HttpStatusCode.NotFound) {
            return <NotFoundPage />;
        }
    }

    return <SomeErrorPage />;
}
