import { LinkItem } from "../../models";

export const ALL_LINKS: readonly LinkItem[] = Object.freeze([
    {
        key: "1867fe6b-491f-487a-abfd-4109655bad81",
        href: "https://blog.jedf.com/",
        title: "Blog",
        caption: "BLOG",
        visible: true,
    },
    {
        key: "57711314-8c05-4c3c-84c5-108e6e1df2c2",
        href: "https://github.com/josendf",
        title: "GitHub",
        caption: "GIT",
        visible: true,
    },
    {
        key: "e6bb9d80-9845-4a4e-8bbf-e833007f3f0e",
        href: "https://www.linkedin.com/in/josendf/",
        title: "LinkedIn",
        caption: "LINKS",
        visible: true,
    },
    {
        key: "65cabab3-2f51-4ed2-9e2c-e6de8cacfe25",
        href: "https://stackoverflow.com/users/986195/jedf",
        title: "Stack Overflow",
        caption: "STACK",
        visible: true,
    },
    {
        key: "2feaa068-b980-40e3-be5d-36bdb48bf4d7",
        href: "https://twitter.com/josendf",
        title: "Twitter",
        caption: "TWEETS",
        visible: false,
    },
]);
