import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { Container } from "inversify";
import ow from "ow";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { MutableSnapshot, RecoilRoot } from "recoil";
import { ConfigVar } from "./common";
import "./index.css";
import { initRouter } from "./routing";
import { ConfigStore, ConfigStoreTypeTag } from "./services";
import {
    defaultTheme,
    initI18n,
    initInjection,
    registerServices,
    reportWebVitals,
} from "./startup";
import { hydrateLocalState } from "./state";

/**
 * Loads the static configuration settings
 */
function loadStaticConfig(container: Container): void {
    const config = container.get<ConfigStore>(ConfigStoreTypeTag);

    config.set(ConfigVar.NODE_ENV, process.env.NODE_ENV);
}

/**
 * Registers and initializes the application services
 *
 * @returns The DI container
 */
async function initServices(): Promise<Container> {
    const container = initInjection();

    registerServices(container);

    loadStaticConfig(container);

    await initI18n(container);

    return container;
}

/**
 * Returns a function that initializes the local state
 *
 * See:
 * - [State Initialization](https://recoiljs.org/docs/api-reference/core/Snapshot#state-initialization)
 *
 * @param container The DI container
 */
function initState(container: Container): (snapshot: MutableSnapshot) => void {
    return function initializeState(snapshot: MutableSnapshot): void {
        hydrateLocalState(container, snapshot);
    };
}

/**
 * Renders the application
 *
 * @param container The DI container
 */
function runApp(container: Container) {
    ow(container, ow.object);

    const initializeState = initState(container);

    const router = initRouter();

    const root = ReactDOM.createRoot(
        document.getElementById("root") as HTMLElement
    );
    root.render(
        <React.StrictMode>
            <RecoilRoot initializeState={initializeState}>
                <CssBaseline />
                <ThemeProvider theme={defaultTheme}>
                    <RouterProvider router={router} />
                </ThemeProvider>
            </RecoilRoot>
        </React.StrictMode>
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
}

initServices()
    .then(runApp)
    .catch(function (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    });
