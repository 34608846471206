import { LoggerMethod } from "./types";

/**
 * A logger
 */
export interface Logger {
    /** Logs the specified `info` level message */
    info: LoggerMethod;

    /** Logs the specified `warning` level message */
    warn: LoggerMethod;

    /** Logs the specified `error`` level message */
    error: LoggerMethod;
}

/** The {@link Logger} interface type tag */
export const LoggerTypeTag = Symbol.for("Logger");
