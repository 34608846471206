import { Router as RemixRouter } from "@remix-run/router";
import { createBrowserRouter } from "react-router-dom";
import { Routes } from "./routes";

/**
 * Initializes the router.
 *
 * See:
 * -[createBrowserRouter](https://reactrouter.com/en/main/routers/create-browser-router)
 */
export function initRouter(): RemixRouter {
    const router = createBrowserRouter(
        Object.entries(Routes).map(function ([, value]) {
            return value.config;
        })
    );

    return router;
}
