import ow from "ow";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isNonEmptyString } from "../common";

export interface UsePageMetaOptions {
    /** The document title */
    readonly title: string;
}

function formatDocumentTitle(siteTitle: string, title: unknown): string {
    return isNonEmptyString(title) ? title.concat(" / ", siteTitle) : siteTitle;
}

/**
 * Sets the page metadata.
 *
 * @param options The page metadata options
 */
export function usePageMeta(options: UsePageMetaOptions) {
    ow(options, ow.object);

    const { t } = useTranslation();

    const title = formatDocumentTitle(t("site_title"), options.title);

    useEffect(function () {
        document.title = title;
    });

    return { title };
}
