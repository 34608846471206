/**
 * A configuration store
 */
export interface ConfigStore {
    /**
     * Gets the current value of the specified property.
     *
     * @param name The configuration property name
     */
    get(name: string): string;

    /**
     * Tests whether the specified property is defined.
     *
     * @param name The configuration property name or path
     * @returns `true` if the property name is defined
     */
    has(name: string): boolean;

    /**
     * Sets the value of configuration property.
     *
     * @param name The configuration property name
     * @param value The configuration property value
     */
    set(name: string, value: string): void;

    /**
     * Deletes a configuration property.
     *
     * @param name The configuration property name.
     */
    delete(name: string): void;

    /**
     * Merges the specified object properties and values into the configuration
     * store
     */
    merge(source: Record<string, string>): void;

    /**
     * Exports all the properties, the keys and their current values, as
     * a JSON compliant object.
     */
    toJSON(): Record<string, string>;
}

/** The {@link ConfigStore} interface type tag */
export const ConfigStoreTypeTag = Symbol.for("ConfigStore");
