import AlertIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { Routes } from "../../../routing";

export default function SomeErrorPanel() {
    const { t } = useTranslation();

    return (
        <Grid container spacing={2}>
            <Grid
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Alert
                    variant="outlined"
                    severity="error"
                    icon={<AlertIcon fontSize="inherit" />}
                >
                    <AlertTitle>{t("some_error_alert_title")}</AlertTitle>
                    <p>{t("some_error_alert_message")}</p>
                </Alert>
            </Grid>
            <Grid
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Box sx={{ p: 2 }}>
                    <Link
                        component={RouterLink}
                        to={Routes.start.build()}
                        underline="hover"
                    >
                        {t("some_error_continue")}&nbsp;&#x276F;
                    </Link>
                </Box>
            </Grid>
        </Grid>
    );
}
