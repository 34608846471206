import RouterErrorPage from "../pages/errors/RouterErrorPage";
import StartPage from "../pages/start/StartPage";
import { RouteSpec } from "./types";

const start: RouteSpec = {
    name: "start",
    config: {
        path: "/",
        element: <StartPage />,
        errorElement: <RouterErrorPage />,
    },
    build() {
        return "/";
    },
};

export const Routes = {
    start,
};
