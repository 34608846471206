/**
 * The log levels
 */
export enum LogLevel {
    Info = "info",
    Warn = "warn",
    Error = "error",
}

/**
 * A log entry metadata
 */
export interface LoggerMeta {
    [key: string]: any;
    error?: any;
}

/**
 * A log entry
 */
export interface LoggerInfo extends LoggerMeta {
    message: string;
}

/**
 * A logger log method
 */
export interface LoggerMethod {
    (message: string): void;
    (message: string, meta: LoggerMeta): void;
    (info: LoggerInfo): void;
}
