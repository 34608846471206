/**
 * Returns `true` if the specified argument is `null` or `undefined`
 * @param value The value to test
 * @returns `true` if the specified argument is `null` or `undefined`
 */
export function isNil(value: unknown): value is null | undefined {
    return value === undefined || value === null;
}

/**
 * Returns `true` if the specified argument is not `null` nor `undefined`
 * @param value The value to test
 * @returns `true` if the specified argument is not `null` nor `undefined`
 */
export function isNotNil(
    value: unknown
    // eslint-disable-next-line @typescript-eslint/ban-types
): value is boolean | number | string | object | symbol {
    return !isNil(value);
}

/**
 * Returns the `true` if specified argument it is a `string`
 * @param value The value to test
 * @returns  `true` if it is a string
 */
export function isString(value: unknown): value is string {
    if (isNil(value)) {
        return false;
    }
    const type = typeof value;
    return type === "string" || (type === "object" && value instanceof String);
}

/**
 * Returns the `true` if specified argument it is a `number`
 * @param value The value to test
 * @returns  `true` if it is a number
 */
export function isNumber(value: unknown): value is number {
    if (isNil(value)) {
        return false;
    }
    const type = typeof value;
    return type === "number" || (type === "object" && value instanceof Number);
}

/**
 * Returns the `true` if specified argument it is a `boolean`
 * @param value The value to test
 * @returns  `true` if it is a boolean
 */
export function isBoolean(value: unknown): value is boolean {
    if (isNil(value)) {
        return false;
    }
    const type = typeof value;
    return (
        type === "boolean" || (type === "object" && value instanceof Boolean)
    );
}

/**
 * Returns the `true` if specified argument it is a `function`
 * @param value The value to test
 * @returns  `true` if it is a function
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(value: unknown): value is Function {
    if (isNil(value)) {
        return false;
    }
    const type = typeof value;
    return type === "function";
}

/**
 * Returns the `true` if specified argument it is an `object` or `function`.
 * E.g. arrays, functions, objects, regex, `new Number()`, and `new String()`
 * @param value The value to test
 * @returns  `true` if it is an `object` or `function`
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function isObject(value: unknown): value is object | Function {
    if (isNil(value)) {
        return false;
    }
    const type = typeof value;
    return type === "object" || type === "function";
}

/**
 * Returns the `true` if specified argument it is an `object`.
 * E.g. arrays, objects, regex, `new Number()`, and `new String()`
 * @param value The value to test
 * @returns  `true` if it is an `object`
 */
export function isObjectLike(value: unknown): value is object {
    if (isNil(value)) {
        return false;
    }
    return typeof value === "object";
}

/**
 * Returns `true` if the specified argument is not `null` nor `undefined` and
 * and has a `typeof` result of `"object"`
 *
 * @param value The value to test
 * @returns `true` if the specified argument is not `null` nor `undefined` and
 * has a `typeof` result of `"object"`
 */
export function isRecordLike<T extends Record<string, any>>(
    value: unknown
): value is T {
    return isNotNil(value) && isObjectLike(value);
}

/**
 * Returns the `true` if specified argument it is a `string` and
 * the the length is not zero
 * @param value The value to test
 * @returns  `true` if it is a non empty string
 */
export function isNonEmptyString(value: unknown): value is string {
    return isNotNil(value) && isString(value) && value.length > 0;
}

/**
 * Returns `true` if the specified argument is an array of string or
 * the array is empty
 * @param value The value to test
 * @returns  `true` if it is an array of string or the array is empty
 */
export function isArrayOfString(value: unknown): value is string[] {
    return Array.isArray(value) && (value.length < 1 || value.every(isString));
}

/**
 * Returns `true` if the specified argument is an array of non empty strings or
 * the array is empty
 * @param value The value to test
 * @returns  `true` if it is an array of non empty string or the array is empty
 */
export function isArrayOfNonEmptyString(value: unknown): value is string[] {
    return (
        Array.isArray(value) &&
        (value.length < 1 || value.every(isNonEmptyString))
    );
}

/**
 * Returns `true` if the specified argument is:
 * - `null`
 * - `undefined`
 * - An empty string
 * - A NaN numeric value
 * - An empty array
 * - An object not having enumerable properties
 *
 * @param value The value to test
 * @returns `true` if the specified argument is not `null` nor `undefined` and
 * has a `typeof` result of `"object"`
 */
export function isEmpty(value: unknown): boolean {
    if (isNil(value)) {
        return true;
    }

    if (isString(value)) {
        return value.length < 1;
    }

    if (isNumber(value)) {
        return Number.isNaN(value);
    }

    if (Array.isArray(value)) {
        return value.length < 1;
    }

    if (isObjectLike(value)) {
        return Object.keys(value).length < 1;
    }

    return false;
}

/**
 * Matches loosely JSON object serialization string.
 *
 * @param value The value to test
 * @returns `true` if the specified argument matches loosely a JSON object serialization
 */
export function isSerializedJSONObjLike(value: unknown): value is string {
    if (!isNonEmptyString(value)) {
        return false;
    }

    return new RegExp("^\\s*\\{.*\\}\\s*$").test(value);
}

/**
 * Matches loosely JSON array serialization string.
 *
 * @param value The value to test
 * @returns `true` if the specified argument matches loosely a JSON object serialization
 */
export function isSerializedJSONArrayLike(value: unknown): value is string {
    if (!isNonEmptyString(value)) {
        return false;
    }

    return new RegExp("^\\s*\\[.*\\]\\s*$").test(value);
}
