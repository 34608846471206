import { selector } from "recoil";
import { LinkItem } from "../models";
import { linksState } from "./atoms";

/**
 * The state selectors keys
 *
 * See:
 * - [Selectors](https://recoiljs.org/docs/basic-tutorial/selectors}
 */
export enum StateSelectorKey {
    /** The external resources */
    linkQuery = "linkQuery",
}

/** Returns the external resource list */
export const linkQuery = selector<readonly LinkItem[]>({
    key: StateSelectorKey.linkQuery,
    get({ get }) {
        const links = get(linksState);

        return links.filter(function (link) {
            return link.visible;
        });
    },
});
