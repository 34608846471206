import { Container } from "inversify";
import ow from "ow";
import { getOrCreateDIContainer } from "../common";
import {
    ConfigStore,
    ConfigStoreTypeTag,
    ConsoleLogger,
    LocalConfigStore,
    Logger,
    LoggerTypeTag,
    SilentLogger,
} from "../services";

/**
 * Initializes dependency injection (DI) container.
 *
 * See:
 * - [InversifyJS](https://github.com/inversify/InversifyJS)
 *
 * @returns The DI container
 */
export function initInjection(): Container {
    const container = getOrCreateDIContainer();

    return container;
}

/**
 * Registers the application services
 *
 * @param container The Di container
 */
export function registerServices(container: Container): void {
    ow(container, ow.object);

    //
    // Load the Application Insights package as early as possible
    const nodeEnv = process.env.NODE_ENV;
    if (nodeEnv === "production") {
        container
            .bind<Logger>(LoggerTypeTag)
            .toDynamicValue(function (_ctx) {
                return new SilentLogger();
            })
            .inSingletonScope();
    } else {
        container
            .bind<Logger>(LoggerTypeTag)
            .toDynamicValue(function (_ctx) {
                return new ConsoleLogger();
            })
            .inSingletonScope();
    }

    container
        .bind<ConfigStore>(ConfigStoreTypeTag)
        .toDynamicValue(function (_ctx) {
            return new LocalConfigStore();
        })
        .inSingletonScope();
}
