import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import SiteTitle from "../../components/SiteTitle";
import { usePageMeta } from "../../hooks";
import HCLayout from "../../layouts/HCLayout";
import { linkQuery } from "../../state";

function Header() {
    return <SiteTitle />;
}

function Content() {
    const links = useRecoilValue(linkQuery);

    return (
        <Grid container spacing={2}>
            {links.map(function (link) {
                return (
                    <Grid
                        key={link.key}
                        xs={12}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Button
                            href={link.href}
                            title={link.title}
                            size="large"
                        >
                            {link.caption}
                        </Button>
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default function StartPage() {
    const { t } = useTranslation();

    usePageMeta({ title: t("start_page_title") });

    return <HCLayout header={<Header />} content={<Content />} />;
}
