import ow from "ow";
import {
    isBoolean,
    isFunction,
    isNil,
    isNotNil,
    isNumber,
    isObject,
    isString,
} from "./predicates";

/**
 * Returns the specified argument if it is a `string` or
 * an empty string otherwise
 * @param value The value to test
 * @returns The value or an empty string
 */
export function stringOrEmpty(value: unknown): string {
    return isNotNil(value) && isString(value) ? value : "";
}

/**
 * Returns the specified argument if it is a `string` or `null` otherwise
 * @param value The value to test
 * @returns The value or `null`
 */
export function stringOrNull(value: unknown): string | null {
    return isNotNil(value) && isString(value) ? value : null;
}

/**
 * Returns the specified argument if it is a `string` or
 * the specified default value otherwise
 * @param value The value to test
 * @param defValue The default value
 * @returns The value or the default value
 */
export function stringOrDefault<T>(value: unknown, defValue: T): string | T {
    return isNotNil(value) && isString(value) ? value : defValue;
}

/**
 * Returns the specified argument if it is a `string` and
 * the the length is not zero or `null` otherwise
 * @param value The value to test
 * @returns The value or `null`
 */
export function nonEmptyStringOrNull(value: unknown): string | null {
    return isNotNil(value) && isString(value) && value.length > 0
        ? value
        : null;
}

/**
 * Returns the specified argument if it is a non empty `string` or
 * the specified default value otherwise.
 *
 * @param value The value to test
 * @param defValue The default value
 * @returns The value or the default value
 */
export function nonEmptyStringOrDefault(
    value: unknown,
    defValue: string
): string {
    return isNotNil(value) && isString(value) && value.length > 0
        ? value
        : defValue;
}

/**
 * Returns the specified argument if it is a `number` or `NaN` otherwise
 * @param value The value to test
 * @returns The value or `NaN`
 */
export function numberOrNaN(value: unknown): number {
    return isNumber(value) ? value : NaN;
}

/**
 * Returns the specified argument if it is a `number` or zero otherwise
 * @param value The value to test
 * @returns The value or zero
 */
export function numberOrZero(value: unknown): number {
    return isNumber(value) ? value : 0;
}

/**
 * Returns the specified value if it is number or the default value otherwise
 * @param value The value to test
 * @param defaultValue The default value
 * @return The value, if defined, the default value otherwise
 */
export function numberOrDefault(value: unknown, defaultValue: number): number {
    return isNumber(value) ? value : defaultValue;
}

/**
 * Converts the specified value to a number, truncating to integer.
 * Returns the default value if the value is NaN, not finite or unsafe.
 *
 * @param value The value to convert
 */
export function integerOrDefault(value: unknown, defValue: number): number {
    const parsed = Math.floor(Number(value));
    return Number.isSafeInteger(parsed) ? parsed : defValue;
}

/**
 * Returns the specified value if it is an object
 * an empty object otherwise
 * @param value The value to test
 * @returns The value, if defined, an empty object otherwise
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function objectOrEmpty(value: unknown): object {
    return isNil(value) || !isObject(value) ? {} : value;
}

/**
 * Returns the specified value if it is an object
 * `null` otherwise
 *
 * @function objectOrNull
 * @param value The value to test
 * @return the value, if defined, an empty `null` otherwise
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function objectOrNull(value: unknown): object | null {
    return isNil(value) || !isObject(value) ? null : value;
}

/**
 * Returns the textual representation of the specified value
 * If the value is `null` or `undefined` an empty string is returned
 * @param value The value
 * @returns The textual representation
 */
export function show(value: unknown): string {
    if (isNil(value)) {
        return "";
    }

    if (isString(value)) {
        return value;
    }

    if (isNumber(value)) {
        return value.toString();
    }

    if (isBoolean(value)) {
        return value.toString();
    }

    if (Array.isArray(value)) {
        return value.length < 1
            ? ""
            : "[".concat(value.map(show).join(","), "]");
    }

    const valueToString = (value as any).toString;
    if (isFunction(valueToString)) {
        const result = valueToString.call(value);
        if (isString(result)) {
            return result;
        }
    }

    return String(value);
}

/**
 * Truncates a string value to the specified length.
 *
 * @param value The value to truncate
 * @param maxLength The maximum length
 * @returns The truncated string or `undefined` if the provided value is not a string.
 */
export function truncateString(
    value: unknown,
    maxLength: number
): string | undefined {
    ow(maxLength, ow.number.positive);

    if (!isString(value)) {
        return undefined;
    }

    const length = value.length;

    return length > maxLength ? value.slice(0, maxLength) : value;
}

/**
 * Truncates a string value to the specified length adding an ellipsis to
 * indicate more content.
 *
 * @param value The value to truncate
 * @param maxLength The maximum length
 * @returns The truncated string or `undefined` if the provided value is not a
 * string.
 */
export function truncateEllipsis(
    value: unknown,
    maxLength: number
): string | undefined {
    ow(maxLength, ow.number.positive);
    if (!isString(value)) {
        return undefined;
    }

    const length = value.length;

    return length > maxLength
        ? value.slice(0, maxLength - 1).concat("\u2026")
        : value;
}

/**
 * Truncates a string value to the specified length adding an ellipsis to
 * indicate more content.
 *
 * @param value The value to truncate
 * @param maxLength The maximum length
 * @returns The truncated string or an empty string if the provided value is not a
 * string.
 */
export function truncateEllipsisOrEmpty(
    value: unknown,
    maxLength: number
): string {
    const result = truncateEllipsis(value, maxLength);
    return isNil(result) ? "" : result;
}

export const MORE_CONTENT_PADDING = "[\u2026]";

/**
 * Truncates a string value to the specified length adding an ellipsis between
 * brackets to indicate more content.
 *
 * @param value The value to truncate
 * @param maxLength The maximum length
 * @returns The truncated string or `undefined` if the provided value is not a
 * string.
 */
export function truncateMore(
    value: unknown,
    maxLength: number
): string | undefined {
    ow(maxLength, ow.number.greaterThanOrEqual(MORE_CONTENT_PADDING.length));
    if (!isString(value)) {
        return undefined;
    }

    const length = value.length;

    return length > maxLength
        ? value
              .slice(0, maxLength - MORE_CONTENT_PADDING.length)
              .concat(MORE_CONTENT_PADDING)
        : value;
}

/**
 * Truncates a string value to the specified length adding an ellipsis between
 * brackets to indicate more content.
 *
 * @param value The value to truncate
 * @param maxLength The maximum length
 * @returns The truncated string or an empty string if the provided value is not a
 * string.
 */
export function truncateMoreOrEmpty(
    value: unknown,
    maxLength: number
): string | undefined {
    const result = truncateMore(value, maxLength);
    return isNil(result) ? "" : result;
}
