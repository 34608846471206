import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import { ReactNode } from "react";

/** The Header/Content component props  */
export interface HCLayoutProps {
    /** The header */
    readonly header: ReactNode;

    /** The content */
    readonly content: ReactNode;
}

export default function HCLayout(props: HCLayoutProps) {
    return (
        <Container maxWidth="sm">
            <Box sx={{ p: 4 }}>
                <Grid container spacing={2}>
                    <Grid
                        xs={12}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {props.header}
                    </Grid>
                    <Grid
                        xs={12}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {props.content}
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}
